import "@nike/eds/dist/index.css";
import {oktaAuth} from './components/config/OktaConfig'
import {InventoryOverviewAppsyncService} from "./components/shared/InventoryOverviewAppsyncService";
import {AuditOverviewAppsyncService} from "./components/shared/AuditOverviewAppsyncService";
import {InventoryService} from "./components/shared/InventoryService";
import React, {useContext} from "react";
import SnackbarContext from "./providers/snackbar-context";
import {Route, Routes} from "react-router-dom";
import ApplicationSnackbar from "./components/shared/ApplicationSnackbar";
import {InventoryOverview} from "./components/overview/InventoryOverview";
import {InventoryItemDetail} from "./components/detail/InventoryItemDetail";


const inventoryOverviewAppsyncService: InventoryOverviewAppsyncService = new InventoryOverviewAppsyncService(oktaAuth)
const auditOverviewAppsyncService: AuditOverviewAppsyncService = new AuditOverviewAppsyncService(oktaAuth)
const inventoryService: InventoryService = new InventoryService(inventoryOverviewAppsyncService, auditOverviewAppsyncService)

export function App({locationTracker, host}) {
    const snackbarCtx = useContext(SnackbarContext);

    return (
        <div>
            <Routes>
                <Route path="/inventory-overview"
                       element={<InventoryOverview inventoryService={inventoryService} LocationTracker={locationTracker} />}/>
                <Route path="/inventory-overview/details/:materialNumber/:size/:countryOfOrigin/:quality"
                       element={<InventoryItemDetail inventoryService={inventoryService} LocationTracker={locationTracker} host={host} />}/>
            </Routes>
            {snackbarCtx.isDisplayed && <ApplicationSnackbar/>}
        </div>
    );
}

export default App;
