import {InventoryOverviewAppsyncService} from "./InventoryOverviewAppsyncService";
import {AuditOverviewAppsyncService} from "./AuditOverviewAppsyncService";
import gql from "graphql-tag";
import {
    getInventoryForMaterial,
    listInventory
} from "../../graphql/inventory-overview-api/queries";
import {
    transactionsDynamicSearch,
    inventoryDynamicSearch,
    inventoryDetailSearch
} from "../../graphql/audit-overview-api/queries";
import {
    submitManualAdjustment
} from "../../graphql/audit-overview-api/mutations";
import {CustomsStatus, Type} from "../../graphql/audit-overview-api/api";


export class InventoryService {

    constructor(
        private inventoryOverviewAppsyncService: InventoryOverviewAppsyncService,
        private auditOverviewAppsyncService: AuditOverviewAppsyncService
    ) {}

    async getInventoryForMaterial(material: string, nextToken: string): Promise<any> {
        return this.inventoryOverviewAppsyncService.appSyncClient.query({
            query: gql(getInventoryForMaterial), fetchPolicy: 'network-only', variables: {
                material: material,
                nextToken: nextToken
            }
        })
    }

    async getInventoryForMaterialSizeCoo(material: string, size: string, countryOfOrigin: string, quality: string): Promise<any> {
        return this.auditOverviewAppsyncService.appSyncClient.query({
            query: gql(inventoryDetailSearch), fetchPolicy: 'network-only', variables: {
                material: material,
                size: size,
                countryOfOrigin: countryOfOrigin,
                quality: quality
            }
        })
    }

    async listInventory(nextToken: string): Promise<any> {
        return this.inventoryOverviewAppsyncService.appSyncClient.query({
            query: gql(listInventory), fetchPolicy: 'network-only', variables: {
                nextToken: nextToken
            }
        })
    }

    async listTransactions(material: string, size: string, countryOfOrigin: string, quality: string, startDate: Date, endDate: Date,
                           location: string, customsStatus: string, type: string): Promise<any> {
        return this.auditOverviewAppsyncService.appSyncClient.query({
            query: gql(transactionsDynamicSearch), fetchPolicy: 'network-only', variables: {
                material: material,
                size: size,
                countryOfOrigin: countryOfOrigin,
                quality: quality,
                fromTimestamp: startDate != null ? startDate.getTime() : null,
                toTimestamp: endDate != null ? endDate.getTime() : null,
                location: location != null ? location : null,
                customsStatus: customsStatus != null ? customsStatus : null,
                type: type != null ? type : null
            }
        })
    }

    async getAuditInventoryForMaterial(material: string,
                                       size: string,
                                       countryOfOrigin: string,
                                       quality: string,
                                       imbalanceFilter: string,
                                       nextToken: string): Promise<any> {
        return this.auditOverviewAppsyncService.appSyncClient.query({
            query: gql(inventoryDynamicSearch), fetchPolicy: 'network-only', variables: {
                material: material,
                size: size,
                countryOfOrigin: countryOfOrigin,
                quality: quality,
                imbalanceFilter: imbalanceFilter,
                nextToken: nextToken
            }
        })
    }

    async submitManualAdjustment(
        material: string,
        size: string,
        countryOfOrigin: string,
        quality: string,
        location: string,
        type: Type,
        quantity: number,
        customsStatus: CustomsStatus,
        reason: string,
        comment: string
    ): Promise<any> {
        return this.auditOverviewAppsyncService.appSyncClient.mutate({
            mutation: gql(submitManualAdjustment), variables: {
                material: material,
                size: size,
                countryOfOrigin: countryOfOrigin,
                quality: quality,
                location: location,
                type: type,
                quantity: quantity,
                customsStatus: customsStatus,
                reason: reason,
                comment: comment
            }
        });
    }

}