import React from "react";
import {TableCell, Icon} from "@nike/eds";
import moment from 'moment';
import {mapTransactionType} from "../../shared/TransactionTypeMapper";
import { Link } from "react-router-dom";

function TransactionHistoryTableRow({result}) {
    return (
        <tr key={result.transactionId}>
            <TableCell>{moment(result.createdTimestamp).format('DD/MM/YY - HH:mm')}</TableCell>
            <TableCell>{result.businessReference}</TableCell>
            <TableCell>{result.location}</TableCell>
            <TableCell>{result.bondedQuantity}</TableCell>
            <TableCell>{result.nonBondedQuantity}</TableCell>
            <TableCell>{mapTransactionType(result.transactionType, result.action)}</TableCell>
            <TableCell>{result.reason}</TableCell>
            <TableCell>{result.source}</TableCell>
            <TableCell>{result.comment}</TableCell>
            <TableCell>
                {result.flowId 
                    ? <Link to={"/flow-overview?flowId=" + result.flowId} reloadDocument>
                        <Icon name="Show" size="m"/>
                      </Link>
                    : undefined}
            </TableCell>
        </tr>
    );
}

export {TransactionHistoryTableRow};