import * as luxon from 'luxon';

const ourtimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
console.log("Your timezone is set to ", ourtimezone);

const parseDate = (timestamp) => {
    var dt = luxon.DateTime.fromISO(timestamp, {zone: 'UTC'});
    return dt.setZone(ourtimezone);
}

const DateTimeFormat = ({timestamp, format = "dd/MM/yyyy HH:mm:ss"}) => {
    let formatted = "";

    if (!format) {
        format = "dd/MM/yyyy HH:mm:ss";
    }

    if (timestamp) {
        const dt = parseDate(timestamp);
        formatted = dt.toFormat(format);
    }
    return <>{formatted}</>
};

export default DateTimeFormat;