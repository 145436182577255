import React, {useContext, useState} from "react";
import {Button, Text, Box, Modal, ButtonGroup, Spinner} from "@nike/eds";
import {useParams} from "react-router-dom";
import {InventoryService} from "../../shared/InventoryService";
import {CustomsStatus, Type} from "../../../graphql/audit-overview-api/api";
import SnackbarContext from "../../../providers/snackbar-context";
import {edsSpace16} from "@nike/eds/dist/tokens";
import SubmitIcon from './rocket';

interface QuantityAdjustmentModalProps {
    isOpen: boolean;
    onClose: (isConfirmed: boolean) => void;
    quantity: number;
    adjustmentType: string;
    customsStatus: string;
    reason: string;
    comment: string;
    inventoryService: InventoryService;
    host: string
}

function QuantityAdjustmentModal({
                                     isOpen,
                                     onClose,
                                     quantity,
                                     adjustmentType,
                                     customsStatus,
                                     reason,
                                     comment,
                                     inventoryService,
                                     host
                                 }: QuantityAdjustmentModalProps) {

    const params = useParams();
    const snackbarCtx = useContext(SnackbarContext);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const adjustmentAction = adjustmentType === "POSADJ" ? "Add" : "Remove";
    const bondedStatus = customsStatus === "BONDED" ? "bonded" : "non-bonded";
    const locationAction = adjustmentType === "POSADJ" ? "to" : "from";
    const [timestamp, setTimestamp] = useState("");
    const [processing, setProcessing] = useState(false);

    const handleSubmit = () => {
        setProcessing(true);
        let requestType;
        let requestStatus;

        if (adjustmentType === "POSADJ") {
            requestType = Type.POSADJ
        } else {
            requestType = Type.NEGADJ
        }

        if (customsStatus === "BONDED") {
            requestStatus = CustomsStatus.BONDED
        } else {
            requestStatus = CustomsStatus.NON_BONDED
        }

        inventoryService.submitManualAdjustment(
            params.materialNumber as string,
            params.size as string,
            params.countryOfOrigin as string,
            params.quality as string,
            "ELC",
            requestType,
            quantity,
            requestStatus,
            reason,
            comment).then(response => {
            setProcessing(false);
            const responseData = response.data.submitManualAdjustment;
            if (responseData && responseData.timestamp != null) {
                setTimestamp(responseData.timestamp);
                setIsConfirmed(true);
            }
            else {
                snackbarCtx.displayMsg("Failed to submit adjustment 1: " + response.message, "error");
            }}
        ).catch(error => {
            setProcessing(false);
            snackbarCtx.displayMsg("Failed to submit adjustment 2: " + error, "error");
        })

    }

    return (
        <Modal
            isOpen={isOpen}
            onDismiss={() => onClose(isConfirmed)}
            headerSlot={isConfirmed ? "Adjustment " + params.materialNumber + "_" + params.size + "_" + params.countryOfOrigin + "_" +
            timestamp + " sent in" : "Confirm adjustment"}
            footerSlot={
                isConfirmed ? (<> </>) : (
                    <>
                        {processing ? (<Spinner size="medium"/>) : (
                        <ButtonGroup>
                            <Button variant="primary" onClick={handleSubmit}>
                                Confirm
                            </Button>
                            <Button variant="secondary" onClick={() => onClose(false)}>
                                Cancel
                            </Button>
                        </ButtonGroup>
                        )}
                    </>
                )
            }
        >
            <Box>
                {isConfirmed ? (
                    <>
                        <Text>
                            Please note: processing the adjustment will take some time and a refresh of the page is
                            necessary
                        </Text>
                        <div style={{marginTop: edsSpace16}}>
                            <SubmitIcon />
                        </div>
                    </>
                ) : (
                    <>
                        <Text>Are you sure you want to make the following adjustment?</Text>
                        <br />
                        <ul>
                            <li>
                                {adjustmentAction} {quantity} units {bondedStatus} {locationAction} ELC
                            </li>
                            <li>Reason: {reason}</li>
                            <li>Comment: {comment}</li>
                        </ul>
                    </>
                )}
            </Box>
        </Modal>
    );
}

export default QuantityAdjustmentModal;