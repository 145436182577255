import {Skeleton, Table, TableCell, TableHeading} from "@nike/eds";
import {InventoryOverviewTableRow} from './InventoryOverviewTableRow'
import React from "react";
import "./InventoryOverviewTable.css";

function InventoryOverviewTable(props) {

    const headers = [
        {Header: ""},
        {Header: "Material"},
        {Header: "Size"},
        {Header: "Country Of Origin"},
        {Header: "Quality"},
        {Header: "Bonded"},
        {Header: "Non-Bonded"},
        {Header: "Total"},
        {Header: ""},
        {Header: "Details"}
    ];

    return (
        <div className="tableFixHead">
            <Table >
                <thead>
                    <tr>
                        {headers.map(column => (<TableHeading>{column.Header}</TableHeading>))}
                    </tr>
                </thead>
                <tbody>
                {props.loading &&
                    <tr>
                        <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                    </tr>
                }
                {!props.loading && props.searchResults && props.searchResults.map(result => (
                    <InventoryOverviewTableRow inventoryService={props.inventoryService} result={result}/>
                ))}
                </tbody>
            </Table>
        </div>
    )
}

export {InventoryOverviewTable};