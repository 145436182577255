import {
    Button, ButtonGroup,
    Card,
    Radio,
    RadioGroup, Select, SingleValue,
    TextField
} from "@nike/eds";
import React, {useState} from "react";
import {edsSpace16, edsSpace32} from "@nike/eds/dist/tokens";
import QuantityAdjustmentModal from "./QuantityAdjustmentModal";

enum Type {
    POSADJ = "POSADJ",
    NEGADJ = "NEGADJ"
}

enum CustomsStatus {
    BONDED = "BONDED",
    NON_BONDED = "NON_BONDED"
}

function QuantityAdjustmentForm({inventoryService, onClose, host}) {

    const [formState, setFormState] = useState({
        quantity: 0,
        adjustmentType: Type.POSADJ,
        customsStatus: CustomsStatus.BONDED,
        reason: "",
        comment: "",
        quantityHasErrors: false,
        quantityErrorMessage: "",
        reasonHasErrors: false,
        reasonErrorMessage: ""
    })

    const reasonOptions = [
        {value: "Adjust All - AA", label: "Adjust All - AA"},
        {value: "Incorrect Status - IS", label: "Incorrect Status - IS"},
        {value: "Clean Up - KVC", label: "Clean Up - KVC"}
    ]

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    }

    const closeModal = (isConfirmed) => {
        setModalIsOpen(false);
        if (isConfirmed) {
            onClose();
        }
    }

    const handleInputChange = (key: string, value: any) => {
        setFormState((prevState) => ({
            ...prevState,
            [key]: value
        }));
    };

    const handleQuantityFocus = () => {
        if (formState.quantity === 0) {
            setFormState((prevState) => ({
                ...prevState,
                quantity: NaN
            }))
        }
    }

    const validateQuantity = () => {
        const { quantity, reason } = formState;

        let quantityErrorMessage = "";
        let quantityHasError = false;
        let reasonErrorMessage = "";
        let reasonHasError = false;

        if (quantity === 0 || Number.isNaN(quantity)) {
            quantityErrorMessage = "No quantity added";
            quantityHasError = true;
        } else if (quantity < 0) {
            quantityErrorMessage = "Quantity value can not be negative.";
            quantityHasError = true;
        } else if (quantity % 1 !== 0) {
            quantityErrorMessage = "Quantity value can not be a decimal.";
            quantityHasError = true;
        } else if (quantity >= 100) {
            quantityErrorMessage = "Quantity value should be less than 100.";
            quantityHasError = true;
        }

        if (reason.trim() === "") {
            reasonErrorMessage = "No reason selected";
            reasonHasError = true;
        }

        setFormState((prevState) => ({
            ...prevState,
            quantityHasErrors: quantityHasError,
            quantityErrorMessage,
            reasonHasErrors: reasonHasError,
            reasonErrorMessage
        }))

        if (!quantityHasError && !reasonHasError) {
            openModal();
        }
    }

    const formGroupStyle = {
        marginBottom: edsSpace16
    }

    const formGroupLastItemStyle = {
        marginBottom: edsSpace32
    }

    const radioGroupStyle = {
        width: "fit-content",
    }

    return (
        <div className="eds-grid eds-elevation--4">
            <Card>
                <div className="form-group" style={formGroupStyle}>
                    <TextField id="location_text_field" label="Location" value="ELC" readOnly={true} message={" "}/>
                </div>

                <div className="form-group" style={formGroupStyle}>
                    <RadioGroup label="Type"
                                name="typeRadioGroup"
                                valueSelected={formState.adjustmentType}
                                onChange={(e) => handleInputChange("adjustmentType", e.target.value as Type)}
                                className="eds-radio-group eds-grid eds-grid--m-cols-2"
                                style={radioGroupStyle}
                    >
                        <Radio label="Add" value={Type.POSADJ} id="type_radio_1"/>
                        <Radio label="Remove" value={Type.NEGADJ} id="type_radio_2"/>
                    </RadioGroup>
                </div>

                <div className="form-group" style={formGroupStyle}>
                    <TextField id="quantity_text_field" label="Quantity" value={formState.quantity}
                               onChange={(e) => handleInputChange("quantity", e.target.value)}
                               hasErrors={formState.quantityHasErrors} errorMessage={formState.quantityErrorMessage}
                               onFocus={handleQuantityFocus}
                               onWheel={(e) => {(e.target as HTMLElement).blur()}} type="number"/>
                </div>

                <div className="form-group" style={formGroupStyle}>
                    <RadioGroup label="Customs Status"
                                name="customsStatusRadioGroup"
                                valueSelected={formState.customsStatus}
                                onChange={(e) => handleInputChange("customsStatus", e.target.value as CustomsStatus)}
                                className="eds-radio-group eds-grid eds-grid--m-cols-2"
                                style={radioGroupStyle}
                    >
                        <Radio label="Bonded" value={CustomsStatus.BONDED} id="cs_radio_1"/>
                        <Radio label="Non-bonded" value={CustomsStatus.NON_BONDED} id="cs_radio_2"/>
                    </RadioGroup>
                </div>

                <div className="form-group" style={formGroupStyle}>
                    <Select
                        id="menu_text_field"
                        label="Reason"
                        value={formState.reason ? {value: formState.reason, label: formState.reason} : null}
                        onChange={(selectedOption: SingleValue<{ value: string, label: string }>) =>
                            handleInputChange("reason", selectedOption?.value)
                        }
                        options={reasonOptions}
                        placeholder="Click to select reason code"
                        hasErrors={formState.reasonHasErrors}
                        errorMessage={formState.reasonErrorMessage}
                    />
                </div>

                <div className="form-group form-group-last-item" style={formGroupLastItemStyle}>
                    <TextField id="comment_text_field" label="Comment" value={formState.comment}
                               onChange={(e) => handleInputChange("comment", e.target.value)}
                               placeholder="Click to add a comment"/>
                </div>

                <div className="form-group form-group-last-item" style={formGroupStyle}>
                    <ButtonGroup>
                        <Button variant="primary" type="submit" onClick={validateQuantity}>Update Quantity</Button>
                        <Button variant="secondary" type="submit" onClick={() => onClose()}>Cancel</Button>
                    </ButtonGroup>
                </div>

            </Card>

            {modalIsOpen && (
                <QuantityAdjustmentModal isOpen={modalIsOpen} onClose={closeModal} quantity={formState.quantity}
                adjustmentType={formState.adjustmentType} customsStatus={formState.customsStatus} host={host}
                reason={formState.reason} comment={formState.comment} inventoryService={inventoryService}></QuantityAdjustmentModal>
            )}
        </div>
    );
}

export {QuantityAdjustmentForm};