import { TextField } from '@nike/eds'
import { HTMLAttributes } from 'react'

export interface SingleLineInputProps
  extends Omit<
      React.ComponentPropsWithoutRef<typeof TextField>,
      'id' | 'name' | 'value' | 'defaultValue' | 'onBlur'
    >,
    HTMLAttributes<HTMLInputElement> {
  name: string
  label: string
  value?: any
  onChange: any
  beforeSlot?: any
}

const calculatePadding = (beforeSlot, afterSlot ) => {
    if (beforeSlot && afterSlot) {
        return 'var(--eds-space-8) 36px 9px 36px'
    }
    if (beforeSlot) {
        return 'var(--eds-space-8) var(--eds-space-4) 9px 36px'
    }
    if (afterSlot) {
        return 'var(--eds-space-8) 36px 9px var(--eds-space-8)'
    }

    return 'var(--eds-space-8) var(--eds-space-4) 9px var(--eds-space-8)'
}

export function SinglelineSearchInput({
  name,
  label,
  value = '',
  onChange,
  beforeSlot,
  afterSlot,
  type = 'text',
  ...otherProps
}: SingleLineInputProps) {
  return (
    <div className="">
      <label className="search-label">{label}</label>
      <TextField
        id={`${name}Input`}
        value={value}
        label={label}
        name={name}
        onChange={onChange}
        beforeSlot={beforeSlot}
        afterSlot={afterSlot}
        hideLabel={true}
        type={type}
        {...otherProps}
        style={{
          padding: calculatePadding(beforeSlot, afterSlot)
        }}
      ></TextField>
    </div>
  )
}
