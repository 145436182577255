import {Box, Button, ButtonGroup, Card, Chip, Divider, Icon, Text, Radio, RadioGroup} from "@nike/eds";
import {SinglelineSearchInput} from "../shared/search/SinglelineSearchInput";
import React, {useEffect, useState, useCallback} from "react";
import {edsSpace16} from "@nike/eds/dist/tokens";
import {InventoryOverviewSearchFormData} from "./model/InventoryOverviewSearchFormData";
import useSessionStorage from "../../common/hooks/useSessionStorage";

const emptySearchCriteria = (): { materialNumbers: string[], size: string, countryOfOrigin: string, quality: string, imbalanceFilter: string } => {
    return {
        materialNumbers: [],
        size: "",
        countryOfOrigin: "",
        quality: "",
        imbalanceFilter: "ALL"
    }
};

function InventoryOverviewSearchForm({onSearch}) {

    const [searchCriteriaState, setSearchCriteria] = useSessionStorage("searchCriteria", emptySearchCriteria());
    const [materialNumberInput, setMaterialNumberInput] = useState('');
    const [sizeInput, setSizeInput] = useState('');
    const [countryOfOriginInput, setCountryOfOriginInput] = useState('');
    const [qualityInput, setQualityInput] = useState('');
    const [clearAllState, setClearAllState] = useState(false);

    const searchCriteria = searchCriteriaState.value;

    const clearSearchCriteria = () => {
        setSearchCriteria(emptySearchCriteria());
    }

    const clearMaterial = () => {
        setSearchCriteria({
            ...searchCriteria,
            materialNumbers: []
        })
    }

    const clearSize = () => {
        setSearchCriteria({
            ...searchCriteria,
            size: ""
        })
    }

    const clearCountryOfOrigin = () => {
        setSearchCriteria({
            ...searchCriteria,
            countryOfOrigin: ""
        })
    }

    const clearQuality = () => {
        setSearchCriteria({
            ...searchCriteria,
            quality: ""
        })
    }

    const clearAndReload = () => {
        clearSearchCriteria();
        setClearAllState(true);
    }

    const addMaterialHandler = () => {
        if (searchCriteria?.materialNumbers === undefined) {
            setSearchCriteria({
                ...searchCriteria,
                materialNumbers: []
            })
        }

        if (materialNumberInput !== "" && searchCriteria?.materialNumbers?.indexOf(materialNumberInput) < 0) {
            let matArray = searchCriteria?.materialNumbers;
            matArray.push(materialNumberInput);
            setSearchCriteria({
                ...searchCriteria,
                materialNumbers: matArray
            })
            setMaterialNumberInput('')
        }
    }

    const addSizeHandler = () => {
        if (searchCriteria?.size === undefined) {
            setSearchCriteria({
                ...searchCriteria,
                size: ""
            })
        }

        if (sizeInput !== "" && searchCriteria?.size === "") {
            setSearchCriteria({
                ...searchCriteria,
                size: sizeInput
            })
            setSizeInput('')
        }
    }

    const addCountryOfOriginHandler = () => {
        if (searchCriteria?.countryOfOrigin === undefined) {
            setSearchCriteria({
                ...searchCriteria,
                countryOfOrigin: ""
            })
        }

        if (countryOfOriginInput !== "" && searchCriteria?.countryOfOrigin === "") {
            setSearchCriteria({
                ...searchCriteria,
                countryOfOrigin: countryOfOriginInput
            })
            setCountryOfOriginInput('')
        }
    }

    const addQualityHandler = () => {
        if (searchCriteria?.quality === undefined) {
            setSearchCriteria({
                ...searchCriteria,
                quality: ""
            })
        }

        if (qualityInput !== "" && searchCriteria?.quality === "") {
            setSearchCriteria({
                ...searchCriteria,
                quality: qualityInput
            })
            setQualityInput('')
        }
    }

    const sizeInputChangeHandler = (event) => {
        setSizeInput(event.target.value)
    }

    const countryOfOriginInputChangeHandler = (event) => {
        setCountryOfOriginInput(event.target.value)
    }

    const qualityInputChangeHandler = (event) => {
        setQualityInput(event.target.value)
    }

    const materialNumberInputChangeHandler = (event) => {
        setMaterialNumberInput(event.target.value)
    }

    const submitHandler = (event) => {
        event.preventDefault();
        doSearch();
    }

    const doSearch = useCallback((restore = false) => {
        let searchRequestData: InventoryOverviewSearchFormData = {
            materialNumbers: searchCriteria.materialNumbers,
            size: searchCriteria.size,
            countryOfOrigin: searchCriteria.countryOfOrigin,
            quality: searchCriteria.quality,
            imbalanceFilter: searchCriteria.imbalanceFilter
        }
        onSearch(searchRequestData, restore);
    }, [searchCriteria.materialNumbers, searchCriteria.size,
              searchCriteria.countryOfOrigin, searchCriteria.quality,
              searchCriteria.imbalanceFilter, onSearch
    ]);

    useEffect(() => {
        if (searchCriteriaState.restored) {
            doSearch(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchCriteriaState.restored]);


    useEffect(() => {
        doSearch(false)
        setClearAllState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearAllState]);

    const filterStyle = {
        marginTop: "52px"
    }

    const titleStyle = {
        marginBottom: edsSpace16
    }

    const dividerStyle = {
        marginTop: edsSpace16,
        marginBottom: edsSpace16
    }

    const pointerStyle = {
        cursor: "pointer"
    }

    let selechtedImbalanceFilter = searchCriteria.imbalanceFilter;
    if (!selechtedImbalanceFilter) {
        selechtedImbalanceFilter = "ALL";
    }

    return (
        <Box className="eds-grid--m-col-1">
            <Card style={filterStyle}>
                <Text style={titleStyle} font={"title-1"} as={"h1"}>Filter</Text>
                <Box className="eds-grid eds-grid--m-cols-3">
                    <Text font={"title-6"} as={"h6"} className="eds-grid--m-col-1">Balance</Text>
                    <div className="eds-grid--m-col-2">
                        <RadioGroup label=""
                                name="imbalanceFilterRadioGroup"
                                valueSelected={selechtedImbalanceFilter}
                                onChange={e => setSearchCriteria({...searchCriteria, imbalanceFilter: e.target.value})}
                                >
                            <Radio label="All inventory" value="ALL" id="radio-1" />
                            <Radio label="Only inventory with imbalances" value="IMBALANCE" id="radio-2" />
                            <Radio label="Only inventory with no imbalances" value="INBALANCE" id="radio-3" />
                        </RadioGroup>
                    </div>

                    <Text font={"title-6"} as={"h6"} className="eds-grid--m-col-1">Material</Text>
                    <div className="eds-grid--m-col-2">
                        <SinglelineSearchInput
                            name="materialNumbers"
                            label=""
                            value={materialNumberInput}
                            onChange={materialNumberInputChangeHandler}
                            disabled={searchCriteria?.materialNumbers?.length > 0}
                            placeholder={'Enter Material'}
                            beforeSlot={<Icon name="Search"/>}
                            afterSlot={<Icon name="PlusCircle" onClick={addMaterialHandler} style={pointerStyle}/>}
                            width={300}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    addMaterialHandler();
                                }
                            }}
                        />
                    </div>

                    <Text font={"title-6"} as={"h6"} className="eds-grid--m-col-1">Size</Text>
                    <div className="eds-grid--m-col-2">
                        <SinglelineSearchInput
                            name="size"
                            label=""
                            value={sizeInput}
                            onChange={sizeInputChangeHandler}
                            disabled={searchCriteria?.size !== ""}
                            placeholder={'Enter Size'}
                            beforeSlot={<Icon name="Search"/>}
                            afterSlot={<Icon name="PlusCircle" onClick={addSizeHandler} style={pointerStyle}/>}
                            width={300}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    addSizeHandler();
                                }
                            }}
                        />
                    </div>

                    <Text font={"title-6"} as={"h6"} className="eds-grid--m-col-1">Country of Origin</Text>
                    <div className="eds-grid--m-col-2">
                        <SinglelineSearchInput
                            name="countryOfOrigin"
                            label=""
                            value={countryOfOriginInput}
                            onChange={countryOfOriginInputChangeHandler}
                            disabled={searchCriteria?.countryOfOrigin !== ""}
                            placeholder={'Enter Country of Origin'}
                            beforeSlot={<Icon name="Search"/>}
                            afterSlot={<Icon name="PlusCircle" onClick={addCountryOfOriginHandler} style={pointerStyle}/>}
                            width={300}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    addCountryOfOriginHandler();
                                }
                            }}
                        />
                    </div>

                    <Text font={"title-6"} as={"h6"} className="eds-grid--m-col-1">Quality</Text>
                    <div className="eds-grid--m-col-2">
                        <SinglelineSearchInput
                            name="quality"
                            label=""
                            value={qualityInput}
                            onChange={qualityInputChangeHandler}
                            disabled={searchCriteria?.quality !== ""}
                            placeholder={'Enter Quality'}
                            beforeSlot={<Icon name="Search"/>}
                            afterSlot={<Icon name="PlusCircle" onClick={addQualityHandler} style={pointerStyle}/>}
                            width={300}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    addQualityHandler();
                                }
                            }}
                        />
                    </div>

                    <div className="eds-grid--m-col-2">
                        <Box style={dividerStyle}>
                            {searchCriteria?.materialNumbers?.length > 0 &&
                                <Chip
                                    as="button"
                                    afterSlot={<Icon name="Close"/>}
                                    onClick={clearMaterial}
                                >
                                    Material: {searchCriteria?.materialNumbers}
                                </Chip>
                            }
                        </Box>
                        <Box style={dividerStyle}>
                            {searchCriteria?.size !== "" &&
                                <Chip
                                    as="button"
                                    afterSlot={<Icon name="Close"/>}
                                    onClick={clearSize}
                                >
                                    Size: {searchCriteria?.size}
                                </Chip>
                            }
                        </Box>
                        <Box style={dividerStyle}>
                            {searchCriteria?.countryOfOrigin !== "" &&
                                <Chip
                                    as="button"
                                    afterSlot={<Icon name="Close"/>}
                                    onClick={clearCountryOfOrigin}
                                >
                                    COO: {searchCriteria?.countryOfOrigin}
                                </Chip>
                            }
                        </Box>
                        <Box style={dividerStyle}>
                            {searchCriteria?.quality !== "" &&
                                <Chip
                                    as="button"
                                    afterSlot={<Icon name="Close"/>}
                                    onClick={clearQuality}
                                >
                                    Quality: {searchCriteria?.quality}
                                </Chip>
                            }
                        </Box>
                    </div>
                </Box>

                <Divider style={dividerStyle}/>

                <ButtonGroup className="eds-grid eds-grid--m-cols-2">
                    <Button className="eds-grid--m-col-1" variant="primary" size="small" type="submit" onClick={submitHandler}>
                        Apply
                    </Button>
                    <Button className="eds-grid--m-col-1" variant="secondary" size="small" type="button"
                            onClick={clearAndReload}>
                        Clear All
                    </Button>
                </ButtonGroup>
            </Card>
        </Box>
    )
}

export {InventoryOverviewSearchForm};