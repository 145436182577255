/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listInventory = /* GraphQL */ `
  query ListInventory($nextToken: String) {
    listInventory(nextToken: $nextToken) {
      items {
        hashKey
        sortKey
        material
        size
        countryOfOrigin
        bondedQuantity
        nonBondedQuantity
      }
      nextToken
    }
  }
`;
export const getInventoryForMaterial = /* GraphQL */ `
  query GetInventoryForMaterial($material: String!, $nextToken: String) {
    getInventoryForMaterial(material: $material, nextToken: $nextToken) {
      items {
        hashKey
        sortKey
        material
        size
        countryOfOrigin
        bondedQuantity
        nonBondedQuantity
      }
      nextToken
    }
  }
`;
export const getInventoryForMaterialSizeCoo = /* GraphQL */ `
  query GetInventoryForMaterialSizeCoo($hashKey: String!, $nextToken: String) {
    getInventoryForMaterialSizeCoo(hashKey: $hashKey, nextToken: $nextToken) {
      items {
        hashKey
        sortKey
        material
        size
        countryOfOrigin
        bondedQuantity
        nonBondedQuantity
      }
      nextToken
    }
  }
`;
